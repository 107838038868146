import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function DatePickers({ handleChangeFecha }) {
  const classes = useStyles();
  const fechaActual = new Date();
  const dia = (fechaActual.getDate()).toString()
  const mes = (fechaActual.getMonth() + 1).toString()
  const año = (fechaActual.getFullYear()).toString()
  // setFecha(`${año}-${mes.padStart(2, "0")}-${dia.padStart(2, "0")}`)
  const [fecha, setFecha] = useState(`${año}-${mes.padStart(2, "0")}-${dia.padStart(2, "0")}`)
  const [fechadefault, setFechaDefault] = useState("")

  // useEffect(() => {
  // })

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        label="Fecha de Nacimiento"
        type="date"
        required={true}
        defaultValue={fecha}
        className={classes.textField}
        onChange={(_event) => { handleChangeFecha(_event.target.value) }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}
