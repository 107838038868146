

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { navigate } from "../../helpers/queryNavigate"
import { CampaingRenovations } from '../../services/LandingV4/campaingrenovations'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(curp, nombre, oferta) {
  return { curp, nombre, oferta };
}


export default function InteractiveList({ data = [], busqueda }) {
  const classes = useStyles();

  const showName = (nombre) => {
    const dividir = nombre.split(" ")
    let chars = 1;
    let res = []
    for (var i = 0; i < dividir.length; i++) {
      res.push(dividir[i].replace(dividir[i], (c) => c.substr(0, chars) + c.split('').slice(chars).map(v => '*').join('')))
    }
    // for (let value of dividir.length) {
    //   res.push(dividir[value].replace(dividir[value], (c) => c.substr(0, chars) + c.split('').slice(chars).map(v => '*').join('')))
    // }
    return res.join(" ")
  }

  const saveUTM = async (creditApplicationId, source, medium, campaign, browserId, clickId) => {
    const cr = new CampaingRenovations(creditApplicationId);
    const utmssend =
    {
      creditApplicationId,
      source,
      medium,
      campaign,
      browserId,
      clickId
    }

    await cr.saveUTM(utmssend)
      .then((response) => {
        console.log("UTM succesfull...")
      })
      .catch(error => {
        console.log(error)
      })
  }

  const showCurp = (curp) => {
    let caracteres = []
    for (let i = 0; i < (curp.length - 4); i++) {
      caracteres.push('*')
    }
    return caracteres.join('') + curp.substr(-4)
  }

  const irOferta = async (id, tenantId, index) => {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_source = urlParams.get('utm_source')
    const utm_medium = urlParams.get('utm_medium')
    const utm_campaign = urlParams.get('utm_campaign')
    const fbclid = urlParams.get('fbclid')
    const gclid = urlParams.get('gclid')

    window.dataLayer = window.dataLayer || [];
    const sentence = window.location.host
    const local = 'localhost';
    const fisa = 'fisa';
    const aef = 'aef';
    const independencia = 'independencia';
    const apoyoeconomico = 'apoyoeconomico';
    let tagBusqueda = ""
    if (busqueda === 0) {
      tagBusqueda = "CURP"
    }
    if (busqueda === 1) {
      tagBusqueda = "RFC"
    }
    window.dataLayer.push({
      'event': 'consultaRealizada',
      'eventCategory': 'Renovaciones',
      'eventAction': `clic_MiOferta_${tagBusqueda}`,
      'eventLabel': `[${index}_${tenantId}]`
    })

    if ((sentence.includes(local) && tenantId === 'FISA') ||
      (sentence.includes(local) && tenantId === 'AEF') ||
      (sentence.includes(fisa) && tenantId === 'FISA') ||
      (sentence.includes(independencia) && tenantId === 'FISA') ||
      (sentence.includes(aef) && tenantId === 'AEF') ||
      (sentence.includes(apoyoeconomico) && tenantId === 'AEF')) {
      if (fbclid) {
        navigate(`/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&fbclid=${fbclid}`)
      } else if (gclid) {
        navigate(`/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&gclid=${gclid}`)
      }
      else {
        navigate(`/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`)
      }
    }
    if (sentence.includes(fisa) && tenantId === 'AEF') {
      if (fbclid) {
        navigate(`https://aef.tysonbeta.com/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&fbclid=${fbclid}`)
      } else if (gclid) {
        navigate(`https://aef.tysonbeta.com/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&gclid=${gclid}`)
      } else {
        navigate(`https://aef.tysonbeta.com/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`)
      }
    }
    if (sentence.includes(independencia) && tenantId === 'AEF') {
      if (fbclid) {
        navigate(`https://www.apoyoeconomico.com.mx/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&fbclid=${fbclid}`)
      } else if (gclid) {
        navigate(`https://www.apoyoeconomico.com.mx/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&gclid=${gclid}`)
      } else {
        navigate(`https://www.apoyoeconomico.com.mx/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`)
      }
    }
    if (sentence.includes(aef) && tenantId === 'FISA') {
      if (fbclid) {
        navigate(`https://fisa.tysonbeta.com/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&fbclid=${fbclid}`)
      } else if (gclid) {
        navigate(`https://fisa.tysonbeta.com/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&gclid=${gclid}`)
      } else {
        navigate(`https://fisa.tysonbeta.com/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`)
      }
    }
    if (sentence.includes(apoyoeconomico) && tenantId === 'FISA') {
      if (fbclid) { 
        navigate(`https://www.independencia.com.mx/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&fbclid=${fbclid}`)
      } else if (gclid) { 
        navigate(`https://www.independencia.com.mx/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&gclid=${gclid}`)
      } else { 
        navigate(`https://www.independencia.com.mx/tu-oferta?creditApplicationId=${id}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`)
      }
    }
    saveUTM(id, utm_source, utm_medium, utm_campaign, fbclid, gclid)
  }

  let rows = []
  data.map((row, index) => {
    return rows.push(
      createData(showCurp(row.curp), showName(row.nombre), <IconButton edge="end" aria-label="delete">
        <VisibilityIcon color="primary" onClick={() => { irOferta(row.id, row.tenantId, index) }} />
      </IconButton>)
    )
  })

  return (
    <>
      {data.length > 0
        ?
        <>
          <h2>Resultados</h2>
          <p>Nota: Por razones de seguridad hemos ocultado parte de la información que solo tú puedes reconocer.</p>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>ID</TableCell>
            <TableCell align="right">ESTATUS</TableCell> */}
                  <TableCell align="right">CURP</TableCell>
                  <TableCell align="right">NOMBRE</TableCell>
                  <TableCell align="right">VER MI OFERTA </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.nombre}>
                    {/* <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell> */}
                    <TableCell align="right">{row.curp}</TableCell>
                    <TableCell align="right">{row.nombre}</TableCell>
                    <TableCell align="right">{row.oferta}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        :
        <h2>Sin resultados</h2>
      }
    </>
  );
}
